// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	urlApi: "https://gestionaytos.backend.chvconsulting.es/api/", //NO CAMBIAR ESTA URL!!!!
	urlApi2: "https://gestionaytos.backend.chvconsulting.es/api/",
	urlCartografia: "https://cartografiasp.chvconsulting.es/#/auth-token/",

	//produccion: https://backend.patrimoniogest.serprofes.com/api/
	//  https://backend.dev.patrimoniogest.serprofes.com/api/
	// https://gestionaytos.chvconsulting.es/dev-api/api/  antiguo

	//urlCartografia: "https://hdata.shinyapps.io/mapApp/",
	//cartografia funcional: https://cartografia.patrimoniogest.serprofes.com/#/auth-token/
	//cartografia local: "http://localhost:4300/#/auth-token/"
	// local cartografia: 

	// --- Cartografia DEV --
	//urlCartografia: "https://cartografiasp.chvconsulting.es/#/auth-token/",

	  // ------ PARA LOCAL ----
	/*urlApi: "http://localhost:3000/api/", //NO CAMBIAR ESTA URL!!!!
	urlApi2: "http://localhost:3000/api/",
	urlCartografia: "http://localhost:4300/#/auth-token/",
	newApiUrl: "https://gestionaytos.chvconsulting.es/dev-api/api/"*/

	 
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
